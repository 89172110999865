<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="filter-group-bill">
        <div class="row">
          <div class="col-md-3 filter-col">
            <el-input placeholder="Mã người dùng" v-model="user_id"></el-input>
          </div>
          <div class="col-md-3 filter-col">
            <el-input placeholder="Mã hoạt động" v-model="detail_id"></el-input>
          </div>
          <div class="col-md-3 filter-col">
            <p-button class="full-width btn-load-data" @click="loadData">
              <i class="fa fa-plus"></i>
              Load dữ liệu
            </p-button>
          </div>
        </div>
      </div>
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="activities" :actions="actions"
            :actionsTable="actionsTable" />
        </div>
      </div>
    </div>
    <el-dialog title="Xóa hoạt động" :visible.sync="dialogDeleteActivityVisible" width="30%">
      <div><el-radio v-model="delete_type" label="update">Cập nhật trạng thái "Hoạt động đã bị xóa"</el-radio></div>
      <div><el-radio v-model="delete_type" label="delete">Xóa ra khỏi database</el-radio></div>
      <div style="margin-top: 10px;">
        <el-input type="textarea" placeholder="Nhập lý do xóa" v-model="logs"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteActivityVisible = false">Hủy</el-button>
        <el-button type="danger" @click="deleteActivity">Xóa</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Đồng bộ" :visible.sync="dialogSyncActiveties" width="50%">
    <div class="row">
        <div class="col-6">
          <el-input placeholder="User ID" v-model="sync_user_id"></el-input>
        </div>
        <div class="col-6">
          <el-input placeholder="Activity ID" v-model="sync_activity_id"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="sm" @click="dialogSyncActiveties = false">Hủy</el-button>
        <el-button size="sm" type="primary" @click="syncUserActivities">Đồng bộ</el-button>
      </span>
    </el-dialog>

    <loading :active.sync="is_loading"></loading>

  </div>
</template>
<script>
  import { Message, MessageBox, Dialog, Button, Input, Radio } from 'element-ui';
  import MyTable from 'src/components/UIComponents/Table.vue';
  import stravaSchemas from './strava-schemas';
  import dtHelper from 'src/helpers/datatable';
  import MySelect from 'src/components/UIComponents/Select';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      MyTable,
      Message,
      MySelect,
      ElDialog: Dialog,
      ElButton: Button,
      ElInput: Input,
      ElRadio: Radio,
      Loading
    },
    data() {
      return {
        columnDefs: dtHelper.buildColumDefs(stravaSchemas),
        is_loading: false,
        user_id: '',
        detail_id: '',
        sync_user_id: '',
        sync_activity_id: '',
        arr_detail_id: [],
        dialogDeleteActivityVisible: false,
        dialogSyncActiveties: false,
        delete_type: 'update',
        logs: '',
        actionsTable: [{
          title: 'Xóa',
          color: 'text-danger',
          callback: this.removeSelectedActivities
        }],
        activities: [],
        actions: [{
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-simple-remove',
            callback: this.showModalDelete
          }
        ]
      }
    },
    methods: {

      async syncUserActivities() {
        if (!this.sync_user_id) {
          return Message({
            type: 'error',
            message: 'Chưa nhập User ID'
          });
        }
        let obj = {
          user_id: this.sync_user_id,
          activity_id: this.sync_activity_id
        };

        this.is_loading = true;
        await this.$store.dispatch('syncUserActivities', obj).then(res => {
          Message({
            type: 'success',
            message: res.message
          });
        }, error => {
          Message({
            message: error,
            type: 'error'
          });
        });

        this.is_loading = false;
      },

      showModalSync() {
        this.dialogSyncActiveties = true;
      },

      edit(index, row) {
        this.$router.push('/activities/run/' + row.detail_id);
      },

      showModalDelete(index, row) {
        this.arr_detail_id = [row.detail_id];
        this.dialogDeleteActivityVisible = true;
      },

      removeSelectedActivities(rows) {
        this.arr_detail_id = [];
        for (let item of rows) {
          this.arr_detail_id.push(item.detail_id);
        }
        this.dialogDeleteActivityVisible = true;
      },

      async deleteActivity() {
        let data = {
          detail_ids: this.arr_detail_id,
          type: this.delete_type,
          logs: this.logs
        };
        this.is_loading = true;
        await this.$store.dispatch('deleteActivity', data).then(res => {
          Message({
            type: 'success',
            message: 'Hoạt động đã bị xóa'
          });
          this.loadData();
          this.dialogDeleteActivityVisible = false;
        }, error => {
          Message({
            dangerouslyUseHTMLString: true,
            message: error,
            type: 'error'
          });
        });
        this.is_loading = false;
      },

      loadData() {
        if (!this.user_id && !this.detail_id) {
          return Message({
            type: 'error',
            message: 'Nhập mã người dùng hoặc mã hoạt động'
          });
        }

        this.is_loading = true;

        let data = {
          user_id: this.user_id,
          detail_id: this.detail_id
        };

        this.$store.dispatch('fetchActivities', data).then(res => {
          this.activities = res.data;
          setTimeout(_ => {
            this.is_loading = false;
          }, 300);
        });

      },
      remove(index, row) {
        MessageBox.confirm('Xóa hoạt động?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(_ => {
          let user_id = row.user_id;
          this.$store.dispatch('removeActivity', row.activity_id).then(res => {
            if (res.success) {
              Message({
                type: 'success',
                message: res.message
              });
              this.loadActivitiesCheat();
              this.$store.dispatch("calcDistanceUserRace", {
                user_id: user_id,
                race_id: this.race_id
              });
            } else {
              Message({
                type: 'error',
                message: res.message
              });
            }
          });
        }, error => {
          console.error(error);
        });
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('setPageTitle', 'Hoạt động');
      this.$store.dispatch('setCurrentFilterUsers', []);
      this.$store.dispatch('setCurrentActions', [{
        label: 'Đồng bộ hoạt động',
        type: 'primary',
        icon: 'el-icon-refresh',
        callback: this.showModalSync
      }]);
    },
    destroyed() {
      this.$store.dispatch('setCurrentActions', []);
    }
  }

</script>
<style lang="scss" scope>
  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .filter-group-bill {
    padding: 15px;
  }

  .btn-load-data {
    margin: 0;
  }

  .el-table th>.cell {
    font-size: 13px;
  }

</style>
