export default [
  {
    prop: 'user_id',
    label: 'User ID',
    type: 'text',
    minWidth: 100,
    link: 'irace.vn/races/users',
    external: true
  },
  {
    prop: 'user_name',
    label: 'User',
    type: 'text',
    minWidth: 130
  },
  {
    prop: 'detail_id',
    label: 'Activity ID',
    type: 'text',
    link: 'www.strava.com/activities',
    external: true,
    minWidth: 130
  },
  {
    prop: 'title',
    label: 'Title',
    type: 'text',
    minWidth: 150
  },
  {
    prop: 'start_date_local',
    label: 'Date',
    type: 'datetime',
    minWidth: 150
  },
  {
    prop: 'distance',
    label: 'Distance',
    type: 'text',
    minWidth: 120
  },
  {
    prop: 'moving_time',
    label: 'Time',
    type: 'text'
  },
  {
    prop: 'pace',
    label: 'Pace',
    type: 'text'
  },
  {
    prop: 'from_accepted_tag',
    label: 'Accepted tag',
    type: 'number',
    minWidth: 140
  },
  {
    prop: 'visibility',
    label: 'Visibility',
    type: 'text',
    minWidth: 140
  },
  {
    prop: 'manual_created',
    label: 'Tạo thủ công',
    type: 'text',
    minWidth: 140
  },
  {
    prop: 'splits_metric',
    label: 'Split pace',
    type: 'text',
    minWidth: 140
  },
  {
    prop: 'map',
    label: 'GPS',
    type: 'text',
    minWidth: 100
  },
  {
    prop: 'is_deleted',
    label: 'Xóa',
    type: 'text',
    minWidth: 100
  },
  {
    prop: 'logs',
    label: 'Log',
    type: 'text',
    minWidth: 200
  }
]
